import { bootstrap as bootstrapActionlinks } from '@bootstrap/actionlinks'
import { bootstrap as bootstrapMisc } from '@bootstrap/misc'
import { bootstrap as bootstrapModals } from '@bootstrap/modals'
import { bootstrap as bootstrapCookieConsent } from '@bootstrap/cookieconsent'
import { bootstrap as bootstrapVideoNoDownload } from '@bootstrap/video-nodownload'
import { bootstrap as bootstrapScrollTopButton } from '@bootstrap/scrolltopbutton'
import Delegate from 'ftdomdelegate'

const bodyDelegate = new Delegate(document.body);

export function bootstrap() {
  bootstrapActionlinks(bodyDelegate)
  bootstrapMisc(bodyDelegate)
  bootstrapModals(bodyDelegate)
  bootstrapCookieConsent()
  bootstrapVideoNoDownload()
  bootstrapScrollTopButton()
}
