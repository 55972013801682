export function forEach(array, callback, scope?) {
  for (var i = 0; i < array.length; i++) {
    callback.call(scope, array[i], i); // passes back stuff we need
  }
}

export function extend(obj, src) {
  for (var key in src) {
    if (src.hasOwnProperty(key)) obj[key] = src[key];
  }
  return obj;
}

export function getElementByHashLink(hash: string) {
  const contentId = hash.substr(1)
  return document.getElementById(contentId)
}

export function isDescendant(child: Node, parent: Node) {
     var node = child.parentNode
     while (node != null) {
         if (node == parent) {
             return true
         }
         node = node.parentNode
     }
     return false
}

export function sanitizeElementData(element, options:any = {}) {

  const {
    allowedKeys = [],
    sanitizeKeyFunc,
    sanitizeValueFunc,
  } = options

  let sanitizedDataset = {}
  let value
  let sanitizedKey
  let sanitizedValue

  Object.keys(element.dataset).map(key => {

    if(sanitizeKeyFunc)
      sanitizedKey = sanitizeKeyFunc(key)

    value = element.dataset[key]

         if(value === 'true')  { sanitizedValue = true }
    else if(value === 'false') { sanitizedValue = false }
    else                       { sanitizedValue = value }

    if(sanitizeValueFunc)
      sanitizedValue = sanitizeValueFunc(sanitizedValue)

    if(!allowedKeys || (allowedKeys && allowedKeys.includes(sanitizedKey))) {
        sanitizedDataset[sanitizedKey] = sanitizedValue
    }
  })

  return sanitizedDataset
}

export function createCookie(name, value, days) {
  let date, expires

  if (days) {
    date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    expires = "; expires=" + date.toGMTString()
  } else {
    expires = ""
  }
  document.cookie = name + "=" + value + expires + "; path=/"
}

export function readCookie(name) {
  var i, c,
    nameEQ = name + "=",
    ca = document.cookie.split(';')

  for (i = 0; i < ca.length; i++) {
    c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1, c.length)
    }
    if (c.indexOf(nameEQ) === 0) {
      return c.substring(nameEQ.length, c.length)
    }
  }
  return null;
}

export function eraseCookie(name) {
  createCookie(name, "", -1);
}

export function createElementFromHtml(html) {
  const containerElem = document.createElement('div')
  containerElem.innerHTML = html
  return containerElem.firstChild
}

function whichTransitionEvent() {
  let t,
    el = document.createElement("fakeelement");

  let transitions = {
    "transition"      : "transitionend",
    "OTransition"     : "oTransitionEnd",
    "MozTransition"   : "transitionend",
    "WebkitTransition": "webkitTransitionEnd"
  }

  for (t in transitions){
    if (el.style[t] !== undefined){
      return transitions[t];
    }
  }

  return false;
}

export const transitionEvent = whichTransitionEvent()

function whichAnimationEvent() {
    var t,
        el = document.createElement("fakeelement");

    var animations = {
        "animation"      : "animationend",
        "OAnimation"     : "oAnimationEnd",
        "MozAnimation"   : "animationend",
        "WebkitAnimation": "webkitAnimationEnd"
    }

    for (t in animations){
        if (el.style[t] !== undefined){
            return animations[t];
        }
    }
}

export const animationEvent = whichAnimationEvent()
