import { createCookie } from '@misc/utilities'

export function bootstrap() {
  const cookieconsentMessage = document.getElementById('cookieconsent')

  if(cookieconsentMessage) {
    const cookieconsentButton = document.getElementById('cookieconsent_dismiss')

    if(cookieconsentButton) {
      cookieconsentButton.addEventListener('click', e => {
        e.preventDefault()

        createCookie('cookieconsent_status', 'dismiss', 365)
        cookieconsentMessage.classList.add('cookieconsent-dismissed')
      })
    }
  }
}
