import UserStats from '@components/userstats'

import { render } from 'inferno'
import Delegate from 'ftdomdelegate'

import { forEach } from '@misc/utilities'

declare var M:any

export function bootstrap(delegate:Delegate) {
  bootstrapUserStats()
}

const clickHandlerAjax = (url, successCb) => {

  fetch(`${url}&sesskey=${M.cfg.sesskey}`, {
    method: 'POST',
    mode: 'cors',
    credentials: 'same-origin'
  })
  .then(response => response.json())
  .then(json => {
    console.log(json)
    if(successCb) { successCb() }
  })
}

const bootstrapUserStats = () => {
  const userstatsContainer = document.getElementById('report-userstats-accesstable')
  if(!userstatsContainer) { return }

  const props = {
    stats: [ 0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0 ]
  }

	render(
    <UserStats {...props} />,
		userstatsContainer
	)
}
