export function bootstrap() {
  document.addEventListener('contextmenu', function(e) {
    if(e.target && e.target.tagName == 'VIDEO') {
      e.preventDefault()
    }
  });

  window.addEventListener('load', function load() {
    window.removeEventListener('load', load, false);

    var videoElems = document.querySelectorAll('video');

    for(var i = 0; i < videoElems.length; i++) {
      videoElems[i].controlsList += ' nodownload';
    }
  });
}
