import Component from 'inferno-component'
import classNames from 'classnames'

import Delegate from 'ftdomdelegate'
import { isDescendant } from '@misc/utilities'

interface Props {
	items: any[]
	delegate: Delegate
}

class ListMenu extends Component<Props, any> {
  
	public listElement

	constructor(props) {
		super(props)

		this.state = {
			isOpen: false
		}

		this.handleToggle = this.handleToggle.bind(this)
		this.blurClick = this.blurClick.bind(this)
	}

	componentDidMount() {
		setTimeout(() => {
			this.openMenu()
		}, 1)
	}

	blurClick(e) {
		const element = e.target

		if (!isDescendant(element, this.listElement)) {
			this.closeMenu()
		}
	}

	closeMenu() {
		this.setState({ isOpen: false })
		this.props.delegate.off('click', this.blurClick)
	}

	openMenu() {
		this.props.delegate.on('click', this.blurClick)
		this.setState({ isOpen: true })
	}

	handleToggle(e) {
		e.preventDefault()
		return this.state.isOpen ?
			this.closeMenu() : this.openMenu()
	}

	render() {

		const { items } = this.props

		const classes = classNames(
			'list-menu',
			{
				'open': this.state.isOpen
			}
		)

		return (
			<div ref={el => this.listElement = el} class={classes}>
				<button class="list-toggle fa" onClick={this.handleToggle} />
				<ul class="list-content">
					{
						items.map(item => (
							<li class="item"><a href={item.href || '#'}>{item.title}</a></li>
						))
					}
				</ul>
			</div>
		)
	}
}

export default ListMenu
