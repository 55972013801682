import { render } from 'inferno'
import Delegate from 'ftdomdelegate'
import extend from 'xtend'
import Modal from '@components/modal'

import {
	forEach, getElementByHashLink, sanitizeElementData
} from '@misc/utilities'

import { infernoRemoveRootNode } from '@misc/utilities-inferno'

const allowedModalLinkProps = [
	'notitle', 'iframe',
	'width', 'height'
]

export function bootstrap(delegate: Delegate) {
  delegate.on('click', '.modalLink_inline', openModalInline)
  delegate.on('click', '.learning-sphere-picker-link', openModalLearningSphere)
}

const openModalLearningSphere = (link) => {
	const title = link.textContent
  const content = <LearningSphereList />
  const options = {
    type: 'learning-sphere-picker',
  }

	renderModal({
		title, content, options
	})
}

const openModalInline = (e: Event) => {
  const clickedElement = e.target as HTMLAnchorElement
	let title = clickedElement.textContent
	let hash = clickedElement.hash
	const contentElem = getElementByHashLink(hash)

  if(!contentElem) return

	const contentHtml = contentElem.innerHTML

	if(!contentHtml) return

	const dataOptions = sanitizeElementData(
		clickedElement, {
			allowedKeys: allowedModalLinkProps,
			sanitizeKeyFunc: key => key.replace(/options/, '').toLowerCase()
		})

	if(contentElem.dataset && contentElem.dataset.title) {
		title = contentElem.dataset.title
	}

	const defaultOptions = {
		html: true,
	}

	const options = extend({}, defaultOptions, dataOptions)

  const content = <div dangerouslySetInnerHTML={ { __html: contentHtml } } />

	renderModal({
		title, content, options
	})
}

export const renderModal = (data:any = {}) => {

	let modalContainer = document.getElementById('modal-container')

	if(!modalContainer) {
		modalContainer = document.createElement('div')
		modalContainer.id = 'modal-container'
		document.body.insertBefore(modalContainer, document.body.firstChild)
	}

	const props = {
		destroy: infernoRemoveRootNode(modalContainer),
		...data
	}

	render(
    <Modal {...props} >{data.content}</Modal>,
		modalContainer
	)
}
