import { render} from 'inferno'
import Delegate from 'ftdomdelegate'

import ListMenu from '@components/listmenu'
import { forEach } from '@misc/utilities'

export function bootstrap(delegate:Delegate) {
  delegate.on('click', '.actionlinks', e => {
    e.preventDefault()

    const element = e.target.closest('.actionlinks')

    let items:any = []

    forEach(element.querySelectorAll('li a'), el => {
      items.push({
        title: el.textContent,
        href: el.href,
      })
    })

    let rootNode = document.createElement('div')
    rootNode.className = 'actionlinks-container'

    element.parentNode.replaceChild(rootNode, element)

    render(<ListMenu items={items} delegate={delegate} />, rootNode)
  })
}
