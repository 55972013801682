import Component from 'inferno-component'
import classNames from 'classnames'

import { animationEvent } from '@misc/utilities'

const keyCodes = {
  KEY_ARROW_LEFT: 37,
  KEY_ARROW_RIGHT: 39,
  KEY_ESC: 27,
}

class Modal extends Component<any, any> {

  public wrapperElement: HTMLElement

  constructor(props) {
    super(props)

    this.state = {
      isFading: false,
      padding: 20
    }

    this.scrollbarSet()

    if(this.props.options.beforeOpen)
      this.props.options.beforeOpen()

    // events
    this.handleClickClose = this.handleClickClose.bind(this)
    this.handleClickBackground = this.handleClickBackground.bind(this)
    this.handleKeyup = this.handleKeyup.bind(this)
  }

  componentDidMount() {
    if(this.props.options.onOpen)
      this.props.options.onOpen(this.wrapperElement)

    this.registerKeys()
  }

  componentWillUnmount() {
    this.unregisterKeys()
  }

  handleClickClose(e) {
    e.preventDefault()
    e.stopPropagation()

    this.closeModal()
  }

  handleClickBackground(e) {
    if(!e.target.closest('.modal-dialog'))
      this.closeModal()
  }

  closeModal() {
    this.setState({ isFading: true })

    if(this.props.options.beforeClose)
      this.props.options.beforeClose(this.wrapperElement);

    if (animationEvent) {
      this.wrapperElement.addEventListener(animationEvent, (e: AnimationEvent) => {
        if (e.animationName === 'ModalSimpleFadeOut') {
          this._closeModal()
        }
      })
    }
    else {
      this._closeModal()
    }
  }

  _closeModal() {

    if(this.props.options.onClose) {
      this.props.options.onClose()
    }

    this.scrollbarUnset()
    this.props.destroy()
  }

  renderHeader() {

    const { title = '[Title]', options = {} } = this.props

    if(options.notitle)
      return null

    return (
      <div class="modal-header">
        <h5 class="modal-title">{ title }</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" onClick={this.handleClickClose}>
            <span aria-hidden="true">×</span>
        </button>
      </div>
    )
  }

  renderBody() {
    return (
      <div class="modal-body">
        { this.props.children }
      </div>
    )
  }

  render() {

    const {
      options =  {},
    } = this.props

    const { width, height, type } = options

    let modalStyles = {
    }

    let modalContentStyles = {
      height: height || null,
    }

    const classes = classNames(
      'animate show',
      {
        'fading': this.state.isFading,
        [`modal-${type}`]: type
      }
    )

    const wrapperProps = {
      ref: node => this.wrapperElement = node,
      className: `modal ${classes}`,
      onClick: this.handleClickBackground
    }

    return (
      <div {...wrapperProps}>
        <div class="modal-dialog modal-lg" style={modalStyles}>
          <div class="modal-content">
            { this.renderHeader() }
            { this.renderBody() }
          </div>
        </div>
      </div>
    )
  }

  scrollbarSet() {
    const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth

    document.body.classList.add('modal-open')
    document.body.style.paddingRight = scrollbarWidth + 'px'
  }

  scrollbarUnset() {
    document.body.classList.remove('modal-open')
    document.body.style.paddingRight = '0'
  }

  registerKeys() {
    document.addEventListener('keyup', this.handleKeyup)
  }

  unregisterKeys() {
    document.removeEventListener('keyup', this.handleKeyup)
  }

  handleKeyup(e) {
    e.preventDefault()
    e.stopPropagation()

    switch(e.keyCode) {
      case keyCodes.KEY_ESC: { return this.closeModal() }
    }
  }
}

export default Modal
